.wrapper {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  color: var(--color-text-light);
  align-items: center;

  &::before {
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 1.8rem;
    background-color: var(--color-orange-primary);
    -webkit-mask-box-image: url(../../../img/chevron-right-solid.svg);
    mask-size: cover;
  }
}

.btn {
  font-size: 1.2rem;
  padding: 1rem 2rem;
}

.title {
  font-weight: 300;
  font-size: 2.2rem;
  margin-right: 4rem;
}
