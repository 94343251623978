@use "./assets/mixins" as *;

// VARIABLES

:root {
  // COLORS
  --color-orange-primary: #ffc57e;
  --color-orange-tint: #ffdead;
  --color-blue-primary: #203041;
  --color-blue-secondary: #5e8298;
  --color-text-dark: #333;
  --color-text-light: #f7f7f7;

  // BOX SHADOWS
  --box-shadow-light: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.1);
  --box-shadow-dark: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.2);

  // BORDER RADIUS
  --border-radius-sm: 0.6rem;
}

// RESET

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  @include respond(1200) {
    font-size: 59.375%;
  }

  @include respond(1000) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-blue-secondary);
}

// TYPOGRAPHY

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  font-size: 1.4rem;
}

// GLOBAL CLASSES

body {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  margin: 0 auto;
  padding: 12.8rem 7.2rem 16rem;
  opacity: 1;
  transition: transform 1s cubic-bezier(0, 2, 1, 1.25), opacity 1s ease-out;

  @include respond(1200) {
    padding: 9.6rem 9.6rem 12.8rem;
  }

  @include respond(800) {
    padding: 9.6rem 4.8rem 12.8rem;
  }
}

h2 {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--color-orange-primary);
  margin-bottom: 5rem;
  text-transform: uppercase;

  @include respond(1000) {
    text-align: center;
  }
}

// USED FOR REVEAL SECTIONS ON SCROLL

.container-preload {
  opacity: 0;
  transform: translateX(-12rem);
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(-8rem);
  }

  80% {
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
