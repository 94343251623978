// MEDIA QUERY BREAKPOINTS
$phone: 37.5em;
$tab-port: 56.25em;
$tab-land: 75em;

// MEDIA QUERY MANAGER

@mixin respond($breakpoint) {
  @if $breakpoint == 600 {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == 800 {
    @media only screen and (max-width: 50em) {
      @content;
    }
  }
  @if $breakpoint == 1000 {
    @media only screen and (max-width: 62.5em) {
      @content;
    }
  }
  @if $breakpoint == 1200 {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == 1400 {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin section-border-top {
  position: relative;
  &::before {
    @include absolute-center;
    top: 0;
    content: "";
    height: 2px;
    background-image: radial-gradient(#fff, transparent);
    width: 70%;

    @include respond(1200) {
      width: 85%;
    }
  }
}

@mixin section-border-top-blur {
  position: relative;
  &::before {
    @include absolute-center;
    top: 0;
    content: "";
    height: 2px;
    border-top: 2px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    filter: blur(10px);
  }
}
