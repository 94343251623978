@use "../../assets/mixins" as *;

.wrapper {
  @include section-border-top;
  background-color: var(--color-blue-primary);
  padding: 6.4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.2rem;
}

.icons-container {
  display: flex;
  gap: 4.8rem;
}

.icon {
  fill: #f7f7f7;
  height: 3rem;
  width: 3rem;
  transition: all 0.5s;

  &:hover {
    fill: var(--color-orange-primary);
    height: 3rem;
    width: 3rem;
  }
}

.logo {
  height: 8rem;
  transition: all 0.5s;

  &:hover {
    filter: brightness(115%) saturate(120%);
  }
}
