@use "../../../assets/mixins" as *;

.wrapper {
  @include section-border-top;
  background-color: var(--color-blue-primary);
}

.grid {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 6rem;
  column-gap: 6rem;
  max-width: 80rem;
  margin: 6.4rem auto;

  @include respond(600) {
    grid-template-columns: repeat(2, 1fr);
  }

  &:nth-of-type(2) {
    margin-bottom: 0;
  }
}

.text {
  color: #f7f7f7;
  font-size: 2.6rem;

  @include respond(1000) {
    text-align: center;
  }
}
