@use "../../assets/mixins" as *;

.wrapper {
  background-color: var(--color-blue-primary);
  min-height: 100vh;
  position: relative;
}

.section {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  margin: 0 auto;
  padding: 9.6rem 7.2rem 0;
}

.grid {
  display: grid;
  gap: 2rem;
  margin-bottom: 8rem;
  min-width: 70%;
  align-self: center;
}

.text {
  color: var(--color-text-light);
  font-size: 2.6rem;
  margin-bottom: 4rem;

  @include respond(1000) {
    text-align: center;
  }
}
