@use "../../../assets/mixins" as *;

.wrapper {
  @include section-border-top;
  background-color: var(--color-blue-primary);
  position: relative;

  @include respond(1000) {
    padding: 0 8rem;
  }

  @include respond(600) {
    padding: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 6.4rem;

  @include respond(1000) {
    grid-template-columns: 1fr;
  }
}

.text {
  color: var(--color-text-light);
  font-size: 2.2rem;

  a {
    color: var(--color-orange-primary);
  }

  @include respond(600) {
    text-align: center;
  }
}

.img {
  background-color: var(--color-orange-primary);
  padding: 1.2rem;
  border-radius: 1000rem;
  height: 24rem;
  align-self: center;
  justify-self: center;
}

a.contact {
  &:link,
  &:visited {
    text-transform: uppercase;
    color: var(--color-blue-primary);
    display: inline-block;
    margin-top: 2rem;
  }
}
