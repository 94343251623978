@use "../../../assets/mixins.scss" as *;

.wrapper {
  @include respond(1000) {
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);

    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(-100%);
    z-index: 998;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    transition: all 0.5s ease-in;

    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.list {
  display: flex;
  gap: 4.4rem;
  list-style-type: none;

  @include respond(1000) {
    flex-direction: column;
    gap: 6rem;
    align-items: center;
  }
}

.link {
  &:link,
  &:visited {
    display: inline-block;
    color: #fdfdfd;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s;
    font-weight: 600;
    position: relative;

    @include respond(1000) {
      font-size: 3rem;
    }
  }

  &:hover,
  &:active {
    color: var(--color-orange-primary);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 0.1rem;
    bottom: -0.4rem;
    left: 0;
    background-color: var(--color-orange-primary);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.link.active {
  color: var(--color-orange-primary);

  &::before {
    transform: scaleX(1);
  }
}

.open {
  @include respond(1000) {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
  }
}

a.contact {
  &:link,
  &:visited {
    text-transform: uppercase;
    position: absolute;
    right: 2.4rem;
    top: 0;
    transform: translateY(50%);

    @include respond(1000) {
      display: inline-block;
      position: static;
      font-size: 2.4rem;
      padding: 1.6rem 3.2rem;
    }
  }

  &:hover {
    transform: translateY(calc(50% - 0.4rem));
  }

  &:active {
    transform: translateY(calc(50% - 0.1rem));
  }
}
