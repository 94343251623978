.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.icon {
  height: 8rem;
  width: 8rem;
}

.title {
  color: var(--color-text-light);
  font-size: 2rem;
}
