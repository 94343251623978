@use "../../../assets/mixins" as *;

.background {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-image: url(../../../img/hero-img.webp);
  background-size: cover;
  background-position: bottom left;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;

  @include respond(600) {
    background-image: url(../../../img/hero-img-mobile.webp);
    background-position: bottom right;
  }
}
