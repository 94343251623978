@use "../../../../assets/mixins" as *;

.wrapper,
.overlay {
  width: 100%;
  aspect-ratio: 6/5;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #e7e7e7;
  border-radius: var(--border-radius-sm);
  position: relative;
  box-shadow: 0 2.4rem 4.4rem rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;

  @include respond(800) {
    max-width: 60rem;
  }

  &:hover .overlay {
    opacity: 1;
    visibility: visible;
  }

  &:hover .img {
    filter: blur(3px);
  }
}

.overlay {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  transition: all 0.5s;
  padding: 3.2rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 2.4rem;
  color: #fff;
}

.more-info {
  color: var(--color-orange-primary);
  font-size: 1.6rem;
  font-weight: 700;
  transition: all 0.2s;
  align-self: center;
  padding: 4rem;

  &:hover {
    color: var(--color-orange-tint);
  }
}

.img {
  box-shadow: var(--box-shadow-dark);
  border-radius: var(--border-radius-sm);
  transition: all 0.5s;
  max-width: 80%;
  max-height: 80%;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.btn {
  text-transform: none;
}

.coming-soon {
  position: relative;
  overflow: hidden;
}

.coming-soon:before {
  content: "Coming Soon!";
  position: absolute;
  transform: rotate(-40deg);
  background-color: var(--color-orange-primary);
  width: 150%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 2.4rem;
}
